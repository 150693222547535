.app {
  @apply p-4 h-full bg-zinc-100 text-zinc-900 dark:bg-zinc-900 dark:text-zinc-100;
}

h1 {
  @apply text-2xl;
}

button {
  @apply py-2 px-4 bg-zinc-900 text-zinc-100 dark:bg-zinc-100 dark:text-zinc-900;
}

.scanner {
  @apply w-full max-w-lg h-full absolute left-[-9999px] top-[-9999px];
}

.scanner.scanning {
  @apply top-0 left-0;
}

p {
  @apply mb-4;
}